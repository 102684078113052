<script>
import { mapGetters } from 'vuex';

/**
 * SmsCampaignEditMixin : Provides common functionality for SmsCampaign view editors and previewers.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignEditMixin',
    computed: {
        ...mapGetters('smsCampaign', {
            // UI sub-module //////////
            editorOpen: 'editorOpen',
            editorOpenDisabled: 'editorOpenDisabled',
            editorOverlayActive: 'editorOverlayActive',

            // Validation V0 sub-module //////////
            uiIconClassBasedOnValidationResult: 'uiIconClassBasedOnValidationResult'
        })
    },
    methods: {
        openEdit (editor) {
            this.$store.commit('smsCampaign/setCurrentEditor', editor);
        },
        saveEdit () {
            return this.$store.dispatch('smsCampaign/updateSmsCampaign').then((data) => {
                // Reset currentEditor.
                this.$store.commit('smsCampaign/setCurrentEditor', null);
            }).catch((reason) => {
                this.toastErrorFromError(reason);
            });
        },
        closeEdit () {
            // We do not discard changes on smsCampaign instance because we never change it.
            // Reset currentEditor.
            this.$store.commit('smsCampaign/setCurrentEditor', null);
        }
    }
};
</script>
