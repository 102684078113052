<template>
    <div class="SmsCampaignStatusAndOutcome">
        <div class="mt-4" v-if="smsCampaign.status === 'IN_PROGRESS'">
            <div class="alert alert-info-alt C-Alert">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 weight-5">{{ $t('SMS Campaign is in progress') }}. <i class="far fa-paper-plane"></i></p>
                    <p class="mb-0 font-weight-bolder small">{{ $tc('Recipient', smsCampaign.validRecipientsCount, { count: smsCampaign.validRecipientsCount }) }}</p>
                </div>
            </div>
        </div>
        <div class="mt-4" v-if="smsCampaign.outcome === 'FAIL'">
            <div class="alert alert-danger C-Alert">
                <p class="mb-0 weight-5">{{ $t('SMS Campaign send failed') }}.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * SmsCampaignStatusAndOutcome : State and outcome for SMS Campaigns that are in progress or completed with fail.
 *
 * [T E M P O R A R Y / D E P R E C A T E D] : This component will be replaced with special components.
 * A component based on state and outcome will render a special component that handles only one scenario.
 * For example, a component that displays real-time progress when SMS Campaign is in progress.
 * For example, a component that displays information about a failed campaign with support and contact links.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignStatusAndOutcome',
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign'
        })
    }
};
</script>

<i18n>
{
    "en": {
        "SMS Campaign is in progress": "SMS Campaign is in progress",
        "SMS Campaign send failed": "SMS Campaign send failed",
        "Recipient": "0 Recipients | 1 Recipient | {count} Recipients"
    },
    "el": {
        "SMS Campaign is in progress": "Η Εκστρατεία SMS είναι σε εξέλιξη",
        "SMS Campaign send failed": "Η αποστολή της Εκστρατείας SMS απέτυχε",
        "Recipient": "0 Παραλήπτες | 1 Παραλήπτης | {count} Παραλήπτες"
    }
}
</i18n>
