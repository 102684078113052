<template>
    <div class="SmsCampaignEditWarningSmallDevices mt-4" v-if="smsCampaign.status === 'DRAFT'">
        <div class="alert alert-info C-Alert text-center C-D-None-md-Up">
            <p class="mb-0">{{ $t('sms_campaign_desktop_edit_1') }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * A warning for mobile (or other small) devices users.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 5 August 2020
 */
export default {
    name: 'SmsCampaignEditWarningSmallDevices',
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign'
        })
    }
};
</script>
