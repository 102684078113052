<template>
    <div class="SmsCampaignEditUnitBalance">
        <div class="alert alert-warning C-Alert mb-4" v-if="displayAlert">
            <p class="mb-0 weight-5">
                {{ $t('Insufficient Balance') }}
                <small class="ml-2">
                    {{ $tc('sms_alt_1', unitBalanceBalance, { count: unitBalanceBalance }) }}
                    <span class="mx-1">/</span>
                    {{ $tc('recipient_alt_1', workspaceCountSubscribed, { count: workspaceCountSubscribed }) }}
                </small>
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * SmsCampaignEditUnitBalance : Displays insufficient unit balance.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignEditUnitBalance',
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaignUpdating: 'smsCampaignUpdating',
            workspaceCountSubscribed: 'workspaceCountSubscribed',
            unitBalanceBalance: 'unitBalanceBalance',
            validationResultIndeterminate: 'validationResultIndeterminate'
        }),
        displayAlert () {
            return this.validationResultIndeterminate('unitBalanceValid') === false;
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Insufficient Balance": "Insufficient SMS Unit Balance",
        "sms_alt_1": "no SMS units | one SMS unit | {count} SMS units",
        "recipient_alt_1": "no recipients | one recipient | {count} recipients"
    },
    "el": {
        "Insufficient Balance": "Ανεπαρκής Ισορροπία μονάδων SMS",
        "sms_alt_1": "δεν υπάρχουν μονάδες SMS | μία μονάδα SMS | {count} μονάδες SMS",
        "recipient_alt_1": "δεν υπάρχουν παραλήπτες | ένας παραλήπτης | {count} παραλήπτες"
    }
}
</i18n>
