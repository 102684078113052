<template>
    <div class="SummaryStatisticsV1">
        <element-loading :active="loading"></element-loading>
        <div class="gk-card text-center mb-0 border-bottom-0" style="background: #fafdfd;" v-if="inProgress">
            <p class="mb-0 small text-uppercase">
                <span class="dot-separator">{{ $t('In Progress') }}</span>
                <!-- @future Add more info... -->
            </p>
        </div>
        <div class="gk-card text-center mb-0 border-bottom-0">
            <div class="statistic-group">
                <div class="statistic">
                    <div class="value">{{ totalRecipients }}</div>
                    <div class="label">{{ $t('Recipients') }}</div>
                </div>
                <div class="statistic s-small">
                    <div class="value">{{ specificFieldValue('Delivered_unique') }}</div>
                    <div class="label">{{ $t('Delivered') }}</div>
                </div>
            </div>
        </div>
        <div class="gk-card mb-0 p-0 border-top-0 text-center">
            <span class="text-microcopy--70 mb-0" v-if="loading">{{ $t('Loading') + '...' }}</span>
            <span class="text-microcopy--70 mb-0 cursor-pointer" @click="retrieveAnalytics" v-else><i class="fas fa-sync-alt"></i> {{ $t('Refresh') }}</span>
            <!--
            <span class="text-microcopy--70 mb-0 ml-2 cursor-pointer" @click="retrieveAnalytics">{{ $t('Details') }}</span>
            -->
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import { AnalyticsFieldValueCounterService } from '@/common/services/api.service';

/**
 * Basic SMS Campaign Results as .statistic.
 *
 * @future implementation: Display all others? Get them directly from SmsCampaign.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SummaryStatisticsV1',
    props: {
        smsCampaignId: {
            type: String,
            required: true
        },
        inProgress: {
            type: Boolean,
            default: false,
            required: false
        },
        totalRecipients: {
            type: Number,
            default: 0,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            error: null,
            fieldValueCounter: null,
            strokeWidth: 10
        };
    },
    computed: {
        // It's not very practical. 'send' and 'reject' counter are inc after an event occurs.
        // We need the final number of recipients in the beginning.
        /*
        totalRecipients () {
            // @future : Get recipients from template... and add SMSs in progress....
            let sendTotal = get(this.fieldValueCounter, 'values.send_unique', 0);
            let failTotal = get(this.fieldValueCounter, 'values.reject_unique', 0);
            return sendTotal + failTotal;
        }
        */
    },
    mounted () {
        this.retrieveAnalytics();
    },
    methods: {
        specificFieldValue (name) {
            let key = 'values.' + name;
            return get(this.fieldValueCounter, key, 0);
        },
        specificFieldValuePerc (name) {
            // let sendTotal = get(this.fieldValueCounter, 'values.send_unique', 0);
            // let failTotal = get(this.fieldValueCounter, 'values.reject_unique', 0);
            // let total = sendTotal + failTotal;
            let total = this.totalRecipients;
            let val = get(this.fieldValueCounter, 'values.' + name, 0);
            if (total === 0) return 0.00;
            return parseFloat(((val * 100) / total).toFixed(2));
        },
        retrieveAnalytics () {
            // Start loader.
            this.loading = true;

            // @future : Use a reset controller that has knowledge of business logic.
            let tenantAccountId = this.$store.getters['application/tenantAccountId'];
            if (tenantAccountId === null) {
                tenantAccountId = 'default';
            }
            let name = tenantAccountId + '.' + 'smsCampaign::' + this.smsCampaignId;

            AnalyticsFieldValueCounterService.retrieve(name)
                .then(({ data }) => {
                    this.fieldValueCounter = data;
                })
                .catch((error) => {
                    this.error = error;
                    this.fieldValueCounter = null;
                })
                .finally(() => {
                    // Stop loader.
                    this.loading = false;
                });
        }
    }
};
</script>

<style lang="scss">
    .SummaryStatisticsV1 {

        .statistic-group {
            margin: 0;
        }

        // @future very useful. Add to styles.
        .dot-separator:not(:last-child):after {
            content: "•";
            color: #717171;
            padding: 0 .25rem;
        }
    }
</style>

<i18n>
{
    "en": {
        "In Progress": "In Progress",
        "Recipients": "Recipients",
        "Delivered": "Delivered",
        "Failed": "Failed",
        "Loading": "Loading",
        "Refresh": "Refresh"
    },
    "el": {
        "In Progress": "Σε Εξέλιξη",
        "Recipients": "Παραλήπτες",
        "Delivered": "Παραδόσεις",
        "Loading": "Φορτώνει",
        "Refresh": "Ανανέωση"
    }
}
</i18n>
