<template>
    <div class="SmsCampaignEditPreviewSection SmsCampaignEditBody">

        <!-- OVERLAY -->
        <div class="SmsCampaignEditPreviewSection-Overlay" v-if="editorOverlayActive('body')"></div>

        <!-- EDITOR -->
        <div class="gk-card mb-0" v-if="editorOpen('body')" key="SmsCampaignEditBody-Edit">
            <div class="d-flex">
                <div>
                    <i class="far fa-check-circle text-muted"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Content') }}</p>
                    <div class="row mt-4">
                        <div class="col-8">
                            <el-form :model="formFields"
                                     :rules="formFieldsRules"
                                     ref="editingFormRef"
                                     :label-position="'top'"
                                     class="cltlr-form-label-style-1"
                                     @submit.native.prevent>
                                <el-form-item :label="$t('Content')" prop="body">
                                    <el-input type="textarea" autosize v-model="formFields.body" show-word-limit></el-input>
                                </el-form-item>
                            </el-form>
                            <!--
                            <p class="mb-0 text-microcopy--70">{{ $t('Characters') }}: {{ formFields.body.length }}</p>
                            -->
                            <!-- @future we need a trustful API to get the number of characters and SMS parts -->
                        </div>
                    </div>
                    <div class="my-2">
                        <button class="btn btn-secondary" @click="submitEdit" :disabled="disableSubmitEdit">{{ $t('Save') }}</button>
                        <button class="btn btn-link" @click="discardEdit">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- PREVIEW -->
        <div class="gk-card mb-0" v-else key="SmsCampaignEditBody-Preview">
            <div class="d-flex">
                <div>
                    <i v-bind:class="uiIconClassBasedOnValidationResult('bodyValid')"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Content') }}</p>
                    <div v-if="smsCampaign.body" key="with-body">
                        <p class="mb-0 weight-4">{{ smsCampaign.body }}</p>
                    </div>
                    <p class="mb-0 weight-4 font-italic text-muted" v-else key="without-body">{{ $t('What\'s the content of this campaign?') }}</p>
                </div>
                <div class="C-D-None-md-Down">
                    <button class="btn btn-sm btn-secondary-alt" @click="openEdit('body')" :disabled="editorOpenDisabled()">{{ $t('Edit Content') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ElValidationUtils from '@/common/utils/el-validation.utils';
import { BODY_MIN_LENGTH, BODY_MAX_LENGTH } from '@/modules/SmsCampaign/store/validation.v0.submodule';
import SmsCampaignEditMixin from '@/modules/SmsCampaign/mixins/SmsCampaignEditMixin';

/**
 * SmsCampaignEditBody : Body edit and preview for SmsCampaignView.
 *
 * @see https://github.com/twilio/smssplit
 * @see https://github.com/chadselph/smssplit
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignEditBody',
    mixins: [
        SmsCampaignEditMixin
    ],
    data () {
        return {
            // Form //////////
            formFields: {
                body: ''
            },
            formFieldsRules: {
                body: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(BODY_MIN_LENGTH, BODY_MAX_LENGTH)
                ]
            }
        };
    },
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign'
        }),
        disableSubmitEdit () {
            if (this.formFields.body === null) {
                return true;
            }
            return this.formFields.body.trim() === '';
        }
    },
    mounted () {
        // Map data.
        if (typeof this.smsCampaign.body !== 'string') this.formFields.body = '';
        else this.formFields.body = this.smsCampaign.body.trim();
    },
    methods: {
        submitEdit () {
            // Validate data.
            this.$refs['editingFormRef'].validate((valid) => {
                if (valid) {
                    // Map data.
                    let value;
                    if (typeof this.formFields.body !== 'string') value = '';
                    else value = this.formFields.body.trim();
                    this.$store.commit('smsCampaign/setSmsCampaignField', {
                        field: 'body',
                        value: value
                    });

                    // Call 'saveEdit' from Mixin.
                    this.saveEdit();
                }
            });
        },
        discardEdit () {
            // Map data.
            if (typeof this.smsCampaign.body !== 'string') this.formFields.body = '';
            else this.formFields.body = this.smsCampaign.body.trim();

            // Call 'closeEdit' from Mixin.
            this.closeEdit();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Content": "Content",
        "Save": "Save",
        "Cancel": "Cancel",
        "What's the content of this campaign?": "What's the content of this campaign?",
        "Edit Content": "Edit Content"
    },
    "el": {
        "Content": "Περιεχόμενο",
        "Save": "Αποθήκευση",
        "Cancel": "Ακύρωση",
        "What's the content of this campaign?": "Ποιο είναι το περιεχόμενο αυτής της εκστρατείας;",
        "Edit Content": "Επεξεργασία Περιεχομένου"
    }
}
</i18n>
