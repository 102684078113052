<template>
    <div class="SmsCampaignSentSummary my-4">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <summary-statistics-v1 :sms-campaign-id="smsCampaign.id"
                                       :in-progress="smsCampaign.status === 'IN_PROGRESS'"
                                       :total-recipients="smsCampaign.validRecipientsCount"></summary-statistics-v1>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mt-4 mt-md-0">
                <div class="gk-card mb-0">
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Broadcaster') }}</p>
                        <p class="value">
                            {{ smsCampaign.broadcaster.name }}
                            <br/>
                            {{ smsCampaign.broadcaster.smsCampaignFromName }}
                        </p>
                    </div>
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Workspace') }}</p>
                        <p class="value">{{ smsCampaign.workspace.name }}</p>
                    </div>
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Content') }}</p>
                        <p class="value">{{ smsCampaign.body }}</p>
                    </div>
                    <div class="FieldValueDisplay" v-if="smsCampaign.completionTimestamp">
                        <p class="title">{{ $t('Completion Date') }}</p>
                        <p class="value">{{ smsCampaign.completionTimestamp | dateLiteral }}</p>
                    </div>
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Send Date') }}</p>
                        <p class="value">{{ smsCampaign.sentTimestamp | dateLiteral }}</p>
                    </div>
                    <div class="FieldValueDisplay mb-0">
                        <p class="title">{{ $t('Created Date') }}</p>
                        <p class="value">{{ smsCampaign.meta.createdDate | dateLiteral }}</p>
                    </div>
                    <!--
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Last Modified Date') }}</p>
                        <p class="value">{{ smsCampaign.meta.lastModifiedDate | dateLiteral }}</p>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SummaryStatisticsV1 from '@/modules/SmsCampaign/components/SummaryStatisticsV1';

/**
 * Summary for a non-draft SmsCampaign.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignSentSummary',
    components: {
        SummaryStatisticsV1
    },
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign'
        })
    }
};
</script>

<i18n>
{
    "en": {
        "Broadcaster": "Broadcaster",
        "Content": "Content",
        "Workspace": "Workspace",
        "Completion Date": "Completion Date",
        "Send Date": "Send Date",
        "Created Date": "Created Date",
        "Last Modified Date": "Last Modified Date"
    },
    "el": {
        "Broadcaster": "Μεταδότης",
        "Content": "Περιεχόμενο",
        "Workspace": "Χώρος Εργασίας",
        "Completion Date": "Ημερομηνία και ώρα ολοκλήρωσης",
        "Send Date": "Ημερομηνία και ώρα αποστολής",
        "Created Date": "Ημερομηνία και ώρα δημιουργίας",
        "Last Modified Date": "Ημερομηνία και ώρα επεξεργασίας"
    }
}
</i18n>
