<template>
    <div class="SmsCampaignEdit my-4">
        <sms-campaign-edit-unit-balance></sms-campaign-edit-unit-balance>
        <sms-campaign-edit-workspace></sms-campaign-edit-workspace>
        <sms-campaign-edit-broadcaster></sms-campaign-edit-broadcaster>
        <sms-campaign-edit-body></sms-campaign-edit-body>
        <sms-campaign-edit-actions></sms-campaign-edit-actions>
    </div>
</template>

<script>
import SmsCampaignEditWorkspace from './SmsCampaignEditWorkspace';
import SmsCampaignEditBroadcaster from './SmsCampaignEditBroadcaster';
import SmsCampaignEditBody from './SmsCampaignEditBody';
import SmsCampaignEditActions from './SmsCampaignEditActions';
import SmsCampaignEditUnitBalance from './SmsCampaignEditUnitBalance';

/**
 * SmsCampaignEdit : Renders all editors and previewers for SmsCampaign.
 *
 * - Workspace Preview and Edit
 * - Broadcaster Preview and Edit
 * - Content Preview and Edit
 *
 * The order of the components affects the UI.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignEdit',
    components: {
        SmsCampaignEditUnitBalance,
        SmsCampaignEditBroadcaster,
        SmsCampaignEditWorkspace,
        SmsCampaignEditBody,
        SmsCampaignEditActions
    }
};
</script>
