<template>
    <div class="SmsCampaignRedirectView ViewWrapper">
        <!-- SILENCE IS GOLDEN -->
    </div>
</template>

<script>
/**
 * SmsCampaignRedirectView : behaves as proxy when we have to refresh the smsCampaign view without reloading the window.
 * This view does not render anything. It accepts an ID from params and redirects to SmsCampaignView with that ID.
 *
 * Why this view?
 * - In order to reset store and fetch the smsCampaign with new state
 * --- Example from DRAFT to IN_PROGRESS.
 * --- Example from IN_PROGRESS to COMPLETED.
 * - In order to display the inner components of SmsCampaignView based on its new state.
 *
 * Keep in mind: USE router.replace and NOT router.push
 *
 * @author Dimitris Gkoulis
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignRedirectView',
    mounted () {
        // Get smsCampaignId from route params.
        const smsCampaignId = this.$route.params.id;

        // Check smsCampaignId. If not valid, go to CampaignsView.
        if (smsCampaignId === null || smsCampaignId === undefined) {
            this.$router.replace({ name: 'campaigns' });
        }

        // Replace route.
        this.$router.replace({ name: 'sms-campaign', params: { id: smsCampaignId } });
    }
};
</script>
