<template>
    <div class="SmsCampaignView ViewWrapper LwHaB">
        <clv-head-meta :title="$t('SMS Campaign')"></clv-head-meta>

        <!-- TRANSPARENT LOCK -->
        <clv-interaction-locker :active="smsCampaignUpdating"></clv-interaction-locker>

        <!-- STATE INDICATORS / LOADERS -->
        <element-loading :active="smsCampaignGetting" :is-full-view="true" :lock-parent="true"></element-loading>
        <element-loading :active="smsCampaignDeleting" :is-full-view="true" :lock-parent="true" :text="$t('Deleting SMS Campaign')"></element-loading>
        <element-loading :active="smsCampaignSending" :is-full-view="true" :lock-parent="true" :text="$t('Sending SMS Campaign')"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="smsCampaignNotFound">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'campaigns' }"><i class="fas fa-chevron-left"></i> {{ $t('Browse Campaigns') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="smsCampaign">
            <component :is="activeHeaderComponent"></component>
            <component :is="activeNavComponent"></component>
            <div class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body">
                <div class="container">
                    <sms-campaign-status-and-outcome></sms-campaign-status-and-outcome>
                    <sms-campaign-edit-warning-small-devices></sms-campaign-edit-warning-small-devices>
                    <component :is="activeMainComponent"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterUtils from '@/common/utils/router.utils';
import SmsCampaignEditHeader from './SmsCampaignEditHeader';
import SmsCampaignEdit from './SmsCampaignEdit';
import SmsCampaignResultsHeader from './SmsCampaignResultsHeader';
import SmsCampaignStatusAndOutcome from './SmsCampaignStatusAndOutcome';
import SmsCampaignSentSummary from './SmsCampaignSentSummary';
import SmsCampaignEditWarningSmallDevices from './SmsCampaignEditWarningSmallDevices';

/**
 * SmsCampaignView : manages SmsCampaigns, display results after send.
 *
 * @author Dimitris Gkoulis
 * @createdAt 16 July 2020
 * @lastModifiedAt 7 November 2020 (clv-interaction-locker)
 */
export default {
    name: 'SmsCampaignView',
    components: {
        SmsCampaignStatusAndOutcome,
        SmsCampaignEditWarningSmallDevices
    },
    data () {
        return {
            activeHeaderComponent: null,
            activeNavComponent: null,
            activeMainComponent: null
        };
    },
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaignGetting: 'smsCampaignGetting',
            smsCampaignDeleting: 'smsCampaignDeleting',
            smsCampaignUpdating: 'smsCampaignUpdating',
            smsCampaignNotFound: 'smsCampaignNotFound',
            smsCampaign: 'smsCampaign',
            smsCampaignSending: 'smsCampaignSending'
        })
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                const id = result.toId;

                if (result.mustInitialize) {
                    this.$store.dispatch('smsCampaign/initializeModule', id)
                        .then(() => void 0).catch(() => void 0)
                        .finally(() => {
                            this.explainRoute();
                        });
                } else {
                    // Probably is query change.
                    this.explainRoute();
                }
            },
            immediate: true
        }
    },
    beforeDestroy () {
        // Reset store.
        this.$store.dispatch('smsCampaign/resetModule').then(() => void 0).catch(() => void 0);
    },
    methods: {
        explainRoute () {
            const instance = this.$store.getters['smsCampaign/smsCampaign'];
            // sub-views are not yet implemented.
            // const view = this.$route.query.view;

            if (instance === null || instance === undefined) {
                // We expect that another component will display an error.
                this.activeHeaderComponent = null;
                this.activeNavComponent = null;
                this.activeMainComponent = null;
                return;
            }

            if (instance.status === 'DRAFT') {
                // Set active component.
                this.activeHeaderComponent = SmsCampaignEditHeader;
                this.activeNavComponent = null;
                this.activeMainComponent = SmsCampaignEdit;

                // @future Remove query.view if exists? Ignore?
                return;
            }

            // Set active component.
            this.activeHeaderComponent = SmsCampaignResultsHeader;
            // this.activeNavComponent = SmsCampaignSentNavigation; // Uncomment to enable.
            this.activeNavComponent = null;
            // sub-views are not yet implemented.
            this.activeMainComponent = SmsCampaignSentSummary;
        }
    }
};
</script>

<style lang="scss">
    .SmsCampaignView {
        background: #fff;
    }
    .SmsCampaignEditPreviewSection {
        position: relative;
    }
    .SmsCampaignEditPreviewSection-Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: white;
        z-index: 10;
        opacity: 0.5;
    }
</style>

<i18n>
{
    "en": {
        "SMS Campaign": "SMS Campaign",
        "Browse Campaigns": "Browse Campaigns",
        "Deleting SMS Campaign": "Deleting SMS Campaign",
        "Sending SMS Campaign": "Sending SMS Campaign"
    },
    "el": {
        "SMS Campaign": "Εκστρατεία SMS",
        "Browse Campaigns": "Περιήγηση στις Εκστρατείες",
        "Deleting SMS Campaign": "Γίνεται διαγραφή της Εκστρατείας SMS",
        "Sending SMS Campaign": "Γίνεται αποστολή της Εκστρατείας SMS"
    }
}
</i18n>
