<template>
    <div class="SmsCampaignResultsHeader border-bottom">
        <div class="container">
            <div class="py-3">
                <p class="mb-0 text-microcopy--60 font-weight-bold">{{ $t('SMS CAMPAIGN') }}</p>
                <p class="size-5 weight-7 line-height-2 mb-0">{{ smsCampaign.name | ellipsis(64) }}</p>
                <p class="mb-0 mt-1 text-microcopy--70 text-muted">{{ $t('Sent on') }} {{ smsCampaign.sentTimestamp | dateLiteral }} {{ $t('by') }} {{ smsCampaign.sentBy }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmsCampaignEditMixin from '@/modules/SmsCampaign/mixins/SmsCampaignEditMixin';

/**
 * SmsCampaignResultsHeader : SmsCampaignView Header for results.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignResultsHeader',
    mixins: [
        SmsCampaignEditMixin
    ],
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign'
        })
    }
};
</script>

<i18n>
{
    "en": {
        "SMS CAMPAIGN": "SMS CAMPAIGN",
        "Sent on": "Sent on",
        "by": "by"
    },
    "el": {
        "SMS CAMPAIGN": "ΕΚΣΤΡΑΤΕΙΑ SMS",
        "Sent on": "Στάλθηκε στις",
        "by": "από"
    }
}
</i18n>
