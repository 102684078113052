<template>
    <div class="SmsCampaignEditPreviewSection SmsCampaignEditActions mt-4 text-right">
        <span class="btn-basic-action-1 text-danger small" @click="deleteConfirmation">{{ $t('Delete SMS Campaign') }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * SmsCampaignEditActions : Basic actions for SmsCampaignView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignEditActions',
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign'
        })
    },
    methods: {
        deleteConfirmation () {
            let resp = confirm(this.$t('Delete SMS Campaign?'));
            if (!resp) {
                return;
            }
            this.$store.dispatch('smsCampaign/deleteSmsCampaign').then(() => {
                this.toastDefault('SMS Campaign deleted');
                this.$router.push({ name: 'campaigns' });
            }).catch((reason) => {
                this.toastErrorFromError(reason);
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Delete SMS Campaign": "Delete SMS Campaign",
        "Delete SMS Campaign?": "Delete SMS Campaign?"
    },
    "el": {
        "Delete SMS Campaign": "Διαγραφή Εκστρατείας SMS",
        "Delete SMS Campaign?": "Διαγραφή Εκστρατείας SMS;"
    }
}
</i18n>
