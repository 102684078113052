<template>
    <div class="SmsCampaignEditHeader border-bottom">
        <div class="container">
            <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center py-3">
                <div>
                    <p class="mb-0 text-microcopy--60 font-weight-bold">{{ $t('SMS CAMPAIGN') }}</p>
                    <p class="size-5 weight-7 line-height-2 mb-0">{{ smsCampaign.name | ellipsis(64) }}</p>
                    <p class="mb-0 mt-1 text-microcopy--70 text-muted">
                        <ring size="14" :color="'#1070ca'" v-if="smsCampaignUpdating"></ring> {{ $t('Edited on') }} {{ smsCampaign.meta.lastModifiedDate | dateLiteral }} {{ $t('by') }} {{ smsCampaign.meta.lastModifiedBy }}
                    </p>
                </div>
                <div class="mt-2 mt-md-0">
                    <button class="btn btn-accent1" @click="openSendConfirmation" :disabled="!validationResult('safeToSend')">{{ $t('Send') }}</button>
                </div>
            </div>
        </div>

        <!-- CONFIRMATION MODAL -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="false"
                     modal-theme="light"
                     overlay-theme="dark"
                     ref="sendConfirmationDialogRef">
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Prepare for launch') }}</p>
            </div>
            <div class="position-relative text-center">
                <p class="mb-1">{{ $t('You \'re about to send an SMS Campaign to') }}:</p>
                <p class="mb-2 weight-5">{{ $tc('Person', workspaceCountSubscribed, { count: workspaceCountSubscribed }) }}</p>
                <p class="mb-0 font-weight-bolder small">{{ $t('cannot_be_stopped_notice') }}</p>
                <!-- @see https://mailchimp.com/help/why-we-cant-stop-or-edit-sent-campaigns/ and write in docs -->
            </div>
            <div slot="button">
                <div>
                    <button class="btn btn-secondary" @click="sendSmsCampaign">{{ $t('Send') }}</button>
                    <button class="btn btn-link" @click="closeSendConfirmation">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </sweet-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import Ring from '@/components/loading/Loaders/Ring';
import SmsCampaignEditMixin from '@/modules/SmsCampaign/mixins/SmsCampaignEditMixin';

/**
 * SmsCampaignEditHeader : SmsCampaignView Header for edit.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
export default {
    name: 'SmsCampaignEditHeader',
    components: {
        SweetModal,
        Ring
    },
    mixins: [
        SmsCampaignEditMixin
    ],
    computed: {
        ...mapGetters('smsCampaign', {
            smsCampaign: 'smsCampaign',
            smsCampaignUpdating: 'smsCampaignUpdating',
            workspaceCountSubscribed: 'workspaceCountSubscribed',
            validationResult: 'validationResult'
        })
    },
    methods: {
        sendSmsCampaign () {
            // Close confirmation.
            this.closeSendConfirmation();

            // At this point, we assume that everything is okay and SMS Campaign can be sent!
            this.$store.dispatch('smsCampaign/sendSmsCampaign').then((ignoredData) => {
                this.toastDefault('toast_sms_campaign_in_progress');
                this.$router.replace({ name: 'sms-campaign-redirect', params: { id: this.smsCampaign.id } });
            }).catch((reason) => {
                this.toastErrorFromError(reason);
            });
        },

        // Confirmation Dialog / Modal Handlers //////////
        openSendConfirmation () {
            this.$refs.sendConfirmationDialogRef.open();
        },
        closeSendConfirmation () {
            this.$refs.sendConfirmationDialogRef.close();
        }
    }
};
</script>

<style lang="scss">
    .SmsCampaignEditHeader {

        .sweet-modal-overlay {
            background: rgba(36,28,21,0.5) !important;
        }
        .sweet-modal {
            max-width: 450px !important;
        }
        .sweet-title {
            background: #efeeea;
        }
        .sweet-buttons {
            text-align: center !important;
        }
    }
</style>

<i18n>
{
    "en": {
        "SMS CAMPAIGN": "SMS CAMPAIGN",
        "Edited on": "Edited on",
        "by": "by",
        "Send": "Send",
        "Cancel": "Cancel",
        "Prepare for launch": "Prepare for launch",
        "You 're about to send an SMS Campaign to": "You 're about to send an SMS Campaign to",
        "Person": "0 People | 1 Person | {count} People",
        "cannot_be_stopped_notice": "Once a campaign has been sent, it can't be stopped or edited."
    },
    "el": {
        "SMS CAMPAIGN": "ΕΚΣΤΡΑΤΕΙΑ SMS",
        "Edited on": "Επεξεργάστηκε στις",
        "by": "από",
        "Send": "Αποστολή",
        "Cancel": "Ακύρωση",
        "Prepare for launch": "Προετοιμαστείτε για εκτόξευση",
        "You 're about to send an SMS Campaign to": " Πρόκειται να στείλετε μια Εκστρατεία SMS σε",
        "Person": "0 Άτομα | 1 Άτομο | {count} Άτομα",
        "cannot_be_stopped_notice": "Από τη στιγμή που μία εκστρατεία αποσταλεί, δε μπορεί να σταματήσει ή να τροποποιηθεί."
    }
}
</i18n>
